import React, { useContext, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Provider, useDispatch } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";

import {
  PremierCollectionModuleProps,
  PremierCollectionClientAssetProps,
  installColorConfig,
  SessionInfo,
  CallState,
  PATH_PREMIER_COLLECTION_SHOP_DETAILS,
} from "redmond";
import { useMedalliaFeedback } from "b2b-base/src/components/MedalliaFeedback";
import { isCorpTenant, useIsAutoApprovalEnabled } from "@capone/common";
import {
  ExperimentsHookProvider,
  useExperimentIsVariant,
} from "@capone/experiments";
import { IntlProvider } from "react-intl";
import useWindowEventListener from "./hooks/useWindowEventListener";
import { eventsToListen } from "./utils/events";
import {
  PATH_HOME,
  PATH_HOME_SEARCH,
  PATH_AVAILABILITY,
  PATH_SHOP,
  PATH_ANCILLARY,
  PATH_BOOK,
  PATH_VACATION_RENTALS,
  PATH_VACATION_RENTALS_AVAILABILITY,
  PATH_VACATION_RENTALS_SEARCH,
  PATH_VACATION_RENTALS_SHOP,
  PATH_VACATION_RENTALS_BOOK,
} from "./utils/paths";
import * as frenchTranslations from "./lang/fr.json";
import * as englishTranslations from "./lang/en.json";
import { store } from "./store";
import { PremierCollectionSearch } from "./modules/search";
import { RewardsBanner } from "./modules/rewards/components";
import { PriceMatchBanner } from "./modules/book/components/PriceMatchBanner";
import AxiosInterceptors from "./components/AxiosInterceptors";
import fetchUserInfo from "./api/v0/user/fetchUserInfo";
import {
  setIsFirstLaunch,
  setAgentEmail,
  setIsAutoApprovalEnabled,
} from "./modules/rewards/actions/actions";
import { AgentBanner } from "halifax";
import UserSourceProvider from "./context/userSource";
import { PremierCollectionAvailability } from "./modules/availability";
import ExperimentsProvider, {
  getExperimentVariant,
  getExperimentVariantJSON,
  HOTELS_CFAR,
  useExperiments,
  HOTEL_CFAR_MODEL_V1,
} from "./context/experiments";
import { PremierCollectionShop } from "./modules/shop";
import { PremierCollectionAncillary } from "./modules/ancillary";
import { PremierCollectionBook } from "./modules/book";
import { setAncillaryExperiments } from "./modules/ancillary/actions/actions";
import { VacationRentalShop } from "./modules/vacation-rental-shop";
import { config } from "./api/config";
import { fetchUserPolicies } from "b2b-base/src/api/v1/policies/fetchPolicies";
import { PremierCollectionShopDetails } from "./modules/shop-details";

function loadLocaleData(locale: string): any {
  switch (locale) {
    case "fr":
      return frenchTranslations;
    default:
      return englishTranslations;
  }
}

const generateClassName = createGenerateClassName({
  productionPrefix: "ptPremierCollectionModule",
  seed: "ptPremierCollectionModule",
});

interface PremierCollectionClientContextType
  extends PremierCollectionClientAssetProps {
  isAgentPortal: boolean;
  isAutoApprovalEnabled: boolean;
}

export const ClientContext = React.createContext<
  Partial<PremierCollectionClientContextType>
>({});

const App = (props: PremierCollectionModuleProps) => {
  const {
    theme,
    language,
    colors,
    baseHistory,
    clientAssets,
    isAgentPortal,
    experiments,
  } = props;

  const [activeTheme, setActiveTheme] = useState(theme);
  const [locale, setLocale] = useState(language);
  const [messages, setMessages] = useState(loadLocaleData(locale).default);
  const [sessionInfo, setSessionInfo] = useState(clientAssets.sessionInfo);
  const [policies, setPolicies] = useState(clientAssets.policies);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userInfoResponse = await fetchUserInfo();
        setSessionInfo(userInfoResponse);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    if (!policies && isCorpTenant(config.TENANT)) {
      handleFetchPolicies();
    }
  }, [policies]);

  const handleFetchPolicies = async () => {
    const policiesResponse = await fetchUserPolicies();
    setPolicies(policiesResponse.policyDetail);
  };

  installColorConfig(colors);

  const handleThemeChanged = (e: CustomEvent) => {
    setActiveTheme(e.detail);
    console.log("THEME RECEIVED:", e.detail.palette.type);
  };
  const handleLocaleChanged = (e: CustomEvent) => {
    setLocale(e.detail);
    const messages = loadLocaleData(e.detail);
    setMessages(messages.default);
  };
  useWindowEventListener(eventsToListen.HOST_THEME_CHANGED, handleThemeChanged);
  useWindowEventListener(
    eventsToListen.HOST_LOCALE_CHANGED,
    handleLocaleChanged
  );
  const { firstName, lastName } = sessionInfo?.userInfo || {
    firstName: "",
    lastName: "",
  };

  const isAutoApprovalEnabled = useIsAutoApprovalEnabled(
    clientAssets.policies || policies
  );

  return (
    <Provider store={store}>
      <ExperimentsProvider
        initState={{
          experiments: experiments?.experiments || [],
          trackingProperties: experiments?.trackingProperties,
          callState: CallState.NotCalled,
        }}
      >
        <ExperimentsHookProvider isLoggedIn={Boolean(sessionInfo?.csrfToken)}>
          <UserSourceProvider>
            <Router history={baseHistory}>
              <AxiosInterceptors isAgentPortal={isAgentPortal} />
              <ClientContext.Provider
                value={{
                  ...clientAssets,
                  sessionInfo,
                  policies,
                  isAgentPortal,
                  isAutoApprovalEnabled,
                }}
              >
                <div className="App">
                  <StylesProvider
                    generateClassName={generateClassName}
                    injectFirst
                  >
                    <ThemeProvider theme={activeTheme}>
                      {messages != null ? (
                        <IntlProvider
                          locale={locale}
                          defaultLocale="en"
                          messages={messages}
                        >
                          <Switch>
                            {isAgentPortal ? (
                              <Route path="*">
                                <AgentBanner
                                  firstName={firstName}
                                  lastName={lastName}
                                />
                              </Route>
                            ) : (
                              <>
                                <Route path={PATH_BOOK}>
                                  <PriceMatchBanner />
                                </Route>
                                <Route path="*">
                                  <RewardsBanner />
                                </Route>
                              </>
                            )}
                          </Switch>
                          <Body sessionInfo={sessionInfo} />
                        </IntlProvider>
                      ) : (
                        <div>Loading</div>
                      )}
                    </ThemeProvider>
                  </StylesProvider>
                </div>
              </ClientContext.Provider>
            </Router>
          </UserSourceProvider>
        </ExperimentsHookProvider>
      </ExperimentsProvider>
    </Provider>
  );
};

export const Body = (props: { sessionInfo?: SessionInfo }) => {
  const { sessionInfo } = props;
  const dispatch = useDispatch();
  const expState = useExperiments();

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  useEffect(() => {
    if (sessionInfo) {
      dispatch(setIsFirstLaunch(sessionInfo.isFirstSession));
      if (sessionInfo.isDelegatedSession) {
        dispatch(setAgentEmail(sessionInfo.isDelegatedSession));
      }
    }
  }, [sessionInfo, dispatch]);

  useEffect(() => {
    dispatch(
      setAncillaryExperiments({
        [HOTELS_CFAR]: getExperimentVariant(expState.experiments, HOTELS_CFAR),
        [HOTEL_CFAR_MODEL_V1]: getExperimentVariantJSON(
          expState.experiments,
          HOTEL_CFAR_MODEL_V1
        ),
      })
    );
  }, [expState]);

  const { isAutoApprovalEnabled } = useContext(ClientContext);

  useEffect(() => {
    dispatch(
      setIsAutoApprovalEnabled(
        Boolean(isAutoApprovalEnabled) || isApprovalsV2Enabled
      )
    );
  }, [dispatch, isAutoApprovalEnabled, isApprovalsV2Enabled]);

  useMedalliaFeedback();

  return (
    <Box className="main-section">
      <Route
        path={[
          PATH_HOME,
          PATH_HOME_SEARCH,
          PATH_VACATION_RENTALS,
          PATH_VACATION_RENTALS_SEARCH,
        ]}
        exact
      >
        <PremierCollectionSearch />
      </Route>
      <Route path={[PATH_AVAILABILITY, PATH_VACATION_RENTALS_AVAILABILITY]}>
        <PremierCollectionAvailability />
      </Route>
      <Route path={PATH_SHOP}>
        <PremierCollectionShop />
      </Route>
      <Route path={PATH_PREMIER_COLLECTION_SHOP_DETAILS}>
        <PremierCollectionShopDetails />
      </Route>
      <Route path={PATH_VACATION_RENTALS_SHOP}>
        <VacationRentalShop />
      </Route>
      <Route path={PATH_ANCILLARY}>
        <PremierCollectionAncillary />
      </Route>
      <Route path={PATH_BOOK}>
        <PremierCollectionBook />
      </Route>
      <Route path={PATH_VACATION_RENTALS_BOOK}>
        <PremierCollectionBook isVacationRentalBooking={true} />
      </Route>
    </Box>
  );
};

export default App;
